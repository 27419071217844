import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'
import { MediaQueries as Mq } from '../styles/MediaQueries'

export const OverviewContainer = styled.div`
  max-width: var(--contentWidth);
  margin: 0 auto;
  padding: 4rem;
  ${Mq.sm} {
    padding: 1rem;
  }
  //for CompareCountry component on country page
  .MuiAutocomplete-listbox {
    background: var(--brightBlue);
    color: var(--white);
    max-height: 10rem;
    > li {
      font-size: 1.2rem;
      &:hover {
        background-color: var(--baseBlue);
      }
    }
  }
`
export const IntroText = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.73;
  color: var(--baseBlue);
  padding: ${({ p }) => (p ? `${p}` : '1rem 0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}` : 0)};
  margin-right: 2rem;
  max-width: ${({ maxWidth }) => maxWidth};
`

export const GoalContainer = styled.section`
  position: relative;
  width: 100%;
  background-image: linear-gradient(to right, #dbe6f4, 8%, #fff);
  padding: 1px 0 1rem 0;
  margin: 0 0 2rem 0;
  margin-bottom: ${({ last }) => (last ? '7rem' : null)};
`
export const SectionTitle = styled.header`
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.69;
  color: var(--baseBlue);
  margin-left: 2rem;
  padding: 0.6rem 0;
  ${Mq.sm} {
    font-size: 1.4rem;
  }
`
export const GoalBox = styled.div`
  position: relative;
  width: calc(100% - 2rem);
  background-color: var(--white);
  border: 1px solid var(--lightGray);
  border-radius: 5px;
  margin: 0 -1rem 1rem 2rem;
  padding: ${(props) =>
    props.country ? '.25rem 1rem .25rem 2.5rem' : '1.5rem 1rem 1.5rem 2.5rem'};
  display: grid;
  grid-template-columns: ${(props) =>
    props.country
      ? '3.2fr 1.1fr'
      : props.noData
      ? '.9fr 1.1fr'
      : props.sp
      ? '2.25fr 3.25fr 0.75fr'
      : props.special
      ? '1fr 1fr'
      : '1.25fr 4.5fr 0.75fr'};
  grid-column-gap: ${(props) => (props.noData ? '3rem' : '1rem')};
  ${Mq.md} {
    grid-template-columns: 1fr;
  }
  ${Mq.sm} {
    margin: 0 0rem 1rem 1rem;
  }
  &:hover {
    border: 1px solid var(--brightBlue);
    //background-color: var(--lightestGray);
  }
`
export const GoalId = styled.div`
  position: absolute;
  top: calc(50% - 1.3rem);
  left: -1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  background-color: var(--darkGray);
  color: var(--white);
  border-radius: 50%;
  font-weight: 700;
  font-size: 1.2rem;
`
export const GoalDescription = styled.div`
  font-size: 1.6rem;
  color: var(--baseBlue);

  ${Mq.md} {
    padding-bottom: 1rem;
  }
  ${Mq.sm} {
    font-size: 1.25rem;
  }
`
export const VizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--black);
`
export const ExploreContainer = styled.div`
  display: flex;
  align-items: center;
  //this is for svg inside this div
  fill: var(--baseBlue);
  ${Mq.md} {
    margin-top: 1rem;
    justify-content: flex-end;
  }
`
export const ExploreLabel = styled.div`
  flex: 4;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--baseBlue);
  text-align: right;
`
export const IconBox = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;
  ${Mq.md} {
    flex: unset;
    width: unset;
  }
`
export const PointerLink = styled(Link)`
  display: flex;
  //padding: 4px 0 0 0;
  text-decoration: none;
`
export const PriorityDescription = styled.div`
  font-size: 1.4rem;
  color: var(--darkGray);
  line-height: 1.18;
  ${Mq.md} {
    padding-bottom: 1rem;
  }
  ${Mq.sm} {
    font-size: 1.25rem;
  }
`
export const NoDataPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //this is for svg inside this div
  fill: var(--baseBlue);
`
export const NoData = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ special }) => (special ? '' : 'Bebas Neue')};
  text-transform: uppercase;
  font-weight: ${({ special }) => (special ? 'bold' : 'regular')};
  letter-spacing: ${({ special }) => (special ? '0.15rem' : 0)};
  font-size: ${({ special }) => (special ? '1.1rem' : '1.25rem')};
  background-color: ${({ special }) =>
    special ? 'none' : 'var(--lightestGray)'};
  color: ${({ special }) => (special ? 'var(--baseBlue)' : 'var(--darkGray)')};
  border-radius: 5px;
  min-width: ${({ country }) => (country ? '222px' : 'unset')};
`
