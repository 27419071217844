import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { SmHeadline } from '../styles/SharedStyles'

const HowToReadBox = styled.div`
  display: ${({ type }) => (type === 'cntyPg' ? 'flex' : 'grid')};
  justify-content: space-between;
  grid-template-columns: 1.25fr 2fr 2.75fr;
  grid-column-gap: 5rem;
  padding: 1rem;
  border: 1px solid var(--lightGray);
  border-radius: 5px;
  margin: 1.5rem 0 3.5rem;
  ${Mq.sm} {
    grid-template-columns: 1fr;
    flex-wrap: wrap;
  }
`
const MiddleGroup = styled.div`
  display: flex;
  //uncomment after add rest back in:
  flex-direction: column;
  justify-content: center;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
`
const Label = styled.p`
  color: var(--darkGray);
  font-size: 1.1rem;
  margin-left: 1rem;
`
const Line = styled.div`
  width: 3px;
  height: 2.75rem;
  border-left: 3px solid var(--darkGray);
  border-radius: 3px;
  margin: 0 1.2rem;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: var(--lightGray);
  margin: 0.5rem 0 0.5rem 0.2rem;
`
const Square = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5px;
  background-color: var(--gray);
  margin: 4px 6px;
`
const Rect = styled.div`
  width: 2.5rem;
  height: 1.5rem;
  min-width: 2.5rem;
  background-color: ${({ color }) => `var(--${color})`};
  margin: 4px 6px;
  border: ${({ border }) => `2px solid var(--${border})`};
`

const LastGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const HowToRead = ({ type }) => {
  const { t } = useTranslation()
  return (
    <HowToReadBox type={type}>
      <SmHeadline color={'darkGray'}>
        {type === 'cntyPg'
          ? t('how to read the indicators')
          : t('how to read the charts')}
      </SmHeadline>
      {type !== 'cntyPg' ? (
        <MiddleGroup>
          <Flex>
            <Line />
            <Label>{t('baseline or year first reported')}</Label>
          </Flex>

          {type === 'ig' ? (
            <Flex>
              <Circle>#</Circle>
              <Label>{t('2030 target')}</Label>
            </Flex>
          ) : null}
        </MiddleGroup>
      ) : null}
      {type === 'ig' ? (
        <LastGroup>
          {/* delete this after uncommenting the rest: */}
          {/* <Flex>
            <Circle>#</Circle>
            <Label>{t('2030 target')}</Label>
          </Flex> */}

          <Flex>
            <IoCheckmarkCircle
              color={'#4681de'}
              size={42}
              role="img"
              alt="blue checkmark"
            />
            <Label>{t('on track toward 2030 target')}</Label>
          </Flex>
          <Flex>
            <BsFillExclamationDiamondFill
              color={'#d96038'}
              size={42}
              role="img"
              alt="red exclamation point"
            />
            <Label>{t('off track toward 2030 target')}</Label>
          </Flex>
          <Flex>
            <Square role="img" alt="gray square" />
            <Label>{t('stable or no change')}</Label>
          </Flex>
        </LastGroup>
      ) : type === 'sp' ? (
        <div>
          <Flex>
            <Rect color={'mint'} />
            <Label>{t('positive change since last update')}</Label>
          </Flex>
          <Flex>
            <Rect color={'yellow'} />
            <Label>{t('negative change since last update')}</Label>
          </Flex>
          <Flex>
            <Rect color={'gray'} />
            <Label>{t('no change')}</Label>
          </Flex>
        </div>
      ) : (
        <div>
          <Flex>
            <Rect color={'brightBlue'} />
            <Label>
              <span>
                <strong>{t('yes').toUpperCase()}&nbsp;</strong>
              </span>
              - {t('country achieved strategic priority')}
            </Label>
          </Flex>
          <Flex>
            <Rect color={'darkOrange'} />
            <Label>
              <span>
                <strong>{t('no').toUpperCase()}&nbsp;</strong>
              </span>
              - {t('country did not achieve strategic priority')}
            </Label>
          </Flex>
          <Flex>
            <Rect color={'white'} border={'brightBlue'} />
            <Label>
              <span>
                <strong>{t('No data').toUpperCase()}&nbsp;</strong>
              </span>
              - {t('country did not report data or data is not available')}
            </Label>
          </Flex>
        </div>
      )}
    </HowToReadBox>
  )
}

export default HowToRead
